import TopSection from './comps/TopSection.jsx'
import TextDesc from './comps/TextDesc.jsx'
import Address from './comps/Address.jsx'
import './App.css'

export default function App() {
  return (
      <>
          <div className="main">
            <TopSection/>
            <TextDesc/>
            <Address/>
          </div>
      </>
  );
}

