import './TextDesc.css'

export default function TextDesc() {
    return(
        <>
            <p className="TextDesc">Enter your address below,
                and I'll send you a Hanno® t-shirt</p>

            <div className="disc-area">
                <p className="TextDesc-disc">* Limited quantity, Date unknown, Free.</p>
                {/*<p className="TextDesc-disc">Limited quantity</p>*/}
                {/*<p className="TextDesc-disc">Date unknown</p>*/}
                {/*<p className="TextDesc-disc">Free</p>*/}
            </div>
        </>
    )
}