import './Address.css'
import Address_block from '../yxa-address-block.png'
import Go from '../yxa-go.svg'

export default function Address() {
    return(
        <>

            <form action="submit">
                <table>
                    <tbody>
                        <tr>
                            <td className="address-input">
                                <input type="text" placeholder="Name"/>
                            </td>
                            <td rowSpan="4" className="go"><img src={Go} width="75%"/></td>
                        </tr>
                        <tr>
                            <td className="address-input">
                                <input type="text" placeholder="Address line 1"/>
                            </td>
                        </tr>
                        <tr>
                            <td className="address-input">
                                <input type="text" placeholder="Address line 2"/>
                            </td>
                        </tr>
                        <tr>
                            <td className="address-input postcode">
                                <input type="text" placeholder="Postcode"/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>

        </>
    )
}