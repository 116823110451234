import './TopSection.css'
import Bot1 from '../yxa-bot-1.png'
import Grassroot from '../yxa-grassroot.png'

export default function TopSection() {
    return(
        <>
            <div className="TopSection">
                <img src={Bot1} width="22px" style={{marginRight:'18px'}}/>
                <img src={Grassroot} width="36px"/>
                <p className="TopSection-bottom" style={{opacity:'0.4'}}>------------------------------------------</p>
            </div>
        </>
    )
}